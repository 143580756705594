import React, { useEffect, useRef } from 'react'
import Swiper, { SwiperOptions } from 'swiper'

import { carouselContainer, swiperContainer } from './Carousel.css'
import 'swiper/css/swiper.min.css'

type CarouselProps = {
  options: Array<{ slide: React.ReactNode }>
  settings?: {
    on?: {
      slideChanged?({
        isEnd,
        isBeginning,
      }: {
        isBeginning: boolean
        isEnd: boolean
      }): void
    } & SwiperOptions['on']
  } & SwiperOptions
  className?: string
  id: string
  slideSuffix?: string
  controls?: ({
    next,
    prev,
    slidesTotal,
  }: {
    next: () => void
    prev: () => void
    slidesTotal: number
  }) => void
}

const Carousel = ({
  options = [],
  settings = {},
  className = carouselContainer,
  slideSuffix = '',
  id,
  controls,
}: CarouselProps) => {
  const swiperRef = useRef<Swiper | null>(null)

  useEffect(() => {
    if (!swiperRef.current) {
      const swiperOptions: SwiperOptions = {
        speed: 150,
        preloadImages: false,
        lazy: true,
        touchEventsTarget: 'wrapper',
        longSwipes: true,
        resistanceRatio: 0.5,
        pagination: {
          el: `.swiper-pagination-${id}`,
          type: 'bullets',
          clickable: true,
        },
        on: {
          ...settings.on,
        },
        ...settings,
      }

      swiperRef.current = new Swiper(`#${id}`, swiperOptions)

      swiperRef.current.on('slideChange', function () {
        const { isBeginning, isEnd } = swiperRef.current || {
          isEnd: false,
          isBeginning: false,
        }
        settings.on?.slideChanged?.({ isBeginning, isEnd })
      })
    }

    if (swiperRef.current && controls) {
      controls({
        next: () => swiperRef.current?.slideNext(),
        prev: () => swiperRef.current?.slidePrev(),
        slidesTotal: options.length,
      })
    }

    return () => {
      if (swiperRef.current) {
        swiperRef.current.destroy(true, true)
        swiperRef.current = null
      }
    }
  }, [id])

  return (
    <div id={id} data-testid={id} className={`${swiperContainer} ${className}`}>
      <div className="swiper-wrapper">
        {options.length !== 0 &&
          options.map((item, index) => (
            <div className={`swiper-slide ${slideSuffix}`} key={index}>
              {item.slide}
            </div>
          ))}
      </div>
      <div className="swiper-pagination"></div>
    </div>
  )
}

export default Carousel
